body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-scroll .sub-menu {
    background-color: #3d3325 !important;
}

.item-link > span {
    padding: 15px;
    padding-bottom: 33px;
    display: block;
    color: #777;
    line-height: 20px;
    vertical-align: bottom;
    font-family: 'latolight';
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
}

.item-link:hover span {
    color: #14a9d7;
}

.item-link:hover .sub-menu {
    display: inherit;
}

.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 300px;
    padding: 0 11px;
    padding-bottom: 10px;
    /*box-shadow: 5px 6px 3px 0px #c1c1c1;*/
}

.sub-menu li a {
    color: #777;
    padding: 10px 5px;
    transition: box-shadow 0.5s ease-out;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
}

.sub-menu li a:hover {
    /*border: 1px solid #bbb;*/
    color: #14a9d7;
    font-weight: bold;
    /*font-style: italic;*/
}

i.poly {
    width: 65px;
    min-width: 65px;
    height: 59px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

i.poly.poly-green {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjUiIGhlaWdodD0iNTkiIHZpZXdCb3g9IjAgMCA2NSA1OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+cG9seS1iZy1ncmVlbjwvdGl0bGU+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSItOS4zODUlIiB5MT0iLTM4LjA1MiUiIHgyPSIxMDkuMDE0JSIgeTI9IjEyMy4yNDclIiBpZD0iYSI+PHN0b3Agc3RvcC1jb2xvcj0iIzg2RThDRiIgb2Zmc2V0PSIwJSIvPjxzdG9wIHN0b3AtY29sb3I9IiMzQkM0QTEiIG9mZnNldD0iMTAwJSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0zMjgxLjA1IDk1My41NWwxMi4yODMtMjEuNWE4LjA1OCA4LjA1OCAwIDAgMSA3LTQuMDVoMjQuMzM0YTguMDU4IDguMDU4IDAgMCAxIDcgNC4wNWwxMi4yODMgMjEuNWMxLjQgMi40NSAxLjQgNS40NSAwIDcuOWwtMTIuMjgzIDIxLjVhOC4wNTggOC4wNTggMCAwIDEtNyA0LjA1aC0yNC4zMzRhOC4wNTggOC4wNTggMCAwIDEtNy00LjA1bC0xMi4yODMtMjEuNWE3Ljk1NyA3Ljk1NyAwIDAgMSAwLTcuOXoiIGZpbGw9InVybCgjYSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMjgwIC05MjgpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii4yIi8+PC9zdmc+);
}

i.poly.poly-green-2 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjUiIGhlaWdodD0iNTkiIHZpZXdCb3g9IjAgMCA2NSA1OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+cG9seS1iZy1ncmVlbi0yPC90aXRsZT48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9Ijk2LjA3MyUiIHkxPSI5Mi4yMDQlIiB4Mj0iMTUuMTI2JSIgeTI9IjEyLjE5NSUiIGlkPSJhIj48c3RvcCBzdG9wLWNvbG9yPSIjMEZBNUE1IiBvZmZzZXQ9IjAlIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzZDRDhEOCIgb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0iTTI5ODAuMDUgMTI3LjU1bDEyLjI4My0yMS41YTguMDU4IDguMDU4IDAgMCAxIDctNC4wNWgyNC4zMzRhOC4wNTggOC4wNTggMCAwIDEgNyA0LjA1bDEyLjI4MyAyMS41YzEuNCAyLjQ1IDEuNCA1LjQ1IDAgNy45bC0xMi4yODMgMjEuNWE4LjA1OCA4LjA1OCAwIDAgMS03IDQuMDVoLTI0LjMzNGE4LjA1OCA4LjA1OCAwIDAgMS03LTQuMDVsLTEyLjI4My0yMS41YTcuOTU3IDcuOTU3IDAgMCAxIDAtNy45eiIgZmlsbD0idXJsKCNhKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI5NzkgLTEwMikiIGZpbGwtcnVsZT0iZXZlbm9kZCIgb3BhY2l0eT0iLjE1Ii8+PC9zdmc+)

}

i.poly.poly-yellow {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjUiIGhlaWdodD0iNTkiIHZpZXdCb3g9IjAgMCA2NSA1OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+cG9seS1iZy1ncmVlbjwvdGl0bGU+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSItOS4zODUlIiB5MT0iLTM4LjA1MiUiIHgyPSIxMDkuMDE0JSIgeTI9IjEyMy4yNDclIiBpZD0iYSI+PHN0b3Agc3RvcC1jb2xvcj0iIzg2RThDRiIgb2Zmc2V0PSIwJSIvPjxzdG9wIHN0b3AtY29sb3I9IiMzQkM0QTEiIG9mZnNldD0iMTAwJSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0zMjgxLjA1IDk1My41NWwxMi4yODMtMjEuNWE4LjA1OCA4LjA1OCAwIDAgMSA3LTQuMDVoMjQuMzM0YTguMDU4IDguMDU4IDAgMCAxIDcgNC4wNWwxMi4yODMgMjEuNWMxLjQgMi40NSAxLjQgNS40NSAwIDcuOWwtMTIuMjgzIDIxLjVhOC4wNTggOC4wNTggMCAwIDEtNyA0LjA1aC0yNC4zMzRhOC4wNTggOC4wNTggMCAwIDEtNy00LjA1bC0xMi4yODMtMjEuNWE3Ljk1NyA3Ljk1NyAwIDAgMSAwLTcuOXoiIGZpbGw9InVybCgjYSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMjgwIC05MjgpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii4yIi8+PC9zdmc+);
}

.points .point {
    display: inline-flex;
    padding-bottom: 10px;
}

.points .point span {
    padding-left: 24px;
    overflow: visible;
    line-height: 1.375em;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.points .point span.multi-desc {
    flex-direction: column;
    align-items: inherit;
}

.points .point span.multi-desc small {
    font-weight: normal;
    font-style: italic;
}


.collapse.in .item-link > span {
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: bold;
}

.collapse.in .item-link > span > ul {
    padding-bottom: 0
}


.poly.poly-green img {
    width: 30px;
}

.bg-intro {
    padding-top: 56px;
}

.no-data {
    padding: 20px;
    text-align: center;
    background-color: #e6e6e62e;
    border: 1px solid #ccc;
    border-radius: 5px;
}
/*

Author: Bootstrapthemes
Author URI: http://bootstrapthemes.co

*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body {
    background-color: #fff;
    font-size: 15px;
    color: #565656;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    font-family: 'roboto', sans-serif;
    font-weight: 300;
}

/*==========================================
PRE LOADER
==========================================*/

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fefefe;
    z-index: 99999;
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}

.loaded {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url(../images/preloading.gif);
    background-repeat: no-repeat;
    background-position: center;
    -moz-background-size: cover;
    background-size: cover;
    margin: -20px 0 0 -20px;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

ol,
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}

a,
button,
a span {
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.btn.extra-width {
    padding: 13px 40px;
}

.btn:focus,
.btn:active {
    outline: inherit;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*    direction: rtl;*/
}

p {
    margin: 0 0 15px;
}

a, p {
    font-family: 'latolight';
}

.clear {
    clear: both;
}

img {
    max-width: 100%;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    outline: none;
}

/*.form-control {*/
/*border: 1px solid rgba(0, 0, 0, 0.08);*/
/*font-size: 16px;*/
/*height: 45px;*/
/*}*/

/*.form-control:focus {*/
/*border-color: #f56363;*/
/*}*/

h1,
h2,
h3 {
    /*font-family: 'Lane', sans-serif;*/
    font-weight: 300;
    margin: 0;
}

h4,
h5 {
    font-family: 'latoheavy';
    font-weight: 300;
    margin: 0;
    line-height: 2rem;
}

h1 {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 300;
}

h2 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: .9rem;
}

h3 {
    font-weight: 300;
    margin-bottom: .9rem;
    font-size: 2.1rem;
    line-height: 2.5rem;
}

h4 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.1rem;
}

h5 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

h6 {
    font-size: 1rem;
    margin-bottom: .9rem;
}

p {
    line-height: 1.5rem;
    font-size: 1.1rem;
}

p:last-child {
    margin-bottom: 0px;
}

/*.home-wrap h1,*/
.home-wrap h2,
.home-wrap h3,
.home-wrap h4,
.home-wrap h5,
.home-wrap p {
    color: #ffffff;
    /*font-family: 'roboto', sans-serif;*/
}

.text {
    color: #333333;
}

.colorstext {
    color: #f56363;
}

.coloricon i {
    color: #f56363;
}

.colorsbg {
    background: #f56363;
    color: #ffffff;
}

.lightbg {
    background-color: #f9f9f9;
}

.transition {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.outofsectiontext {
    margin-top: 40px;
}

/*
Section
*/
.sections {
    margin-top: 50px;
    overflow: hidden;
}

.sections.footer {
    padding-bottom: 80px;
}

section .subtitle h5 {
    margin-top: 10px;
    font-size: 1.3rem;
    font-family: 'roboto', sans-serif;
}

.parallax-section {
    max-width: 100%;
    color: #ffffff;
}

section .title-half h2 {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 300;
    margin-bottom: 1.4rem;
}

section .subtitle-half h5 {
    font-weight: 100;
    font-size: 17px;
}


.heading {
    margin-bottom: 4rem;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.60);
    width: 100%;
    min-height: 400px;
}

.overlay-fluid-block {
    background-color: rgba(0, 0, 0, 0.60);
    width: 100%;
    padding: 50px 0px;
}

.home-overlay-fluid {
    background-color: rgba(0, 0, 0, 0.60);
    width: 100%;
    padding: 180px 0px;
}

.overlay-img {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #ffffff;
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

.fluid-blocks-col {
    padding: 70px 40px 0 80px;
}

.fluid-blocks-col-right {
    padding: 70px 80px 0 40px;
}

/*
Separator
*/
.separator {
    height: 1px;
    width: 150px;
    margin: auto;
    background: #dfdfdf;
}

.smallseparator {
    height: 1px;
    width: 80px;
    margin: auto;
    background: #f56363;
}

/*
Button
*/
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary.disabled:hover {
    color: #ffffff;
    /*background-color: #f56363;*/
    /*border-color: #f56363;*/
}


/*.btn {*/
/*border: 1px solid #3c3c3c;*/
/*display: inline-block;*/
/*min-width: 160px;*/
/*padding: 1em;*/
/*height: 55px;*/
/*text-align: center;*/
/*color: #3c3c3c;*/
/*font-style: normal;*/
/*letter-spacing: 1px;*/
/*margin: 0;*/
/*font-size: 16px;*/
/*font-weight: 300;*/
/*border-radius: 0;*/
/*margin-top: 30px;*/
/*}*/

/*.btn:hover {*/
/*background-color: transparent;*/
/*color: #000;*/
/*text-decoration: none;*/
/*}*/

/*.btn-primary {*/
/*color: #fff;*/
/*background-color: #ea6060;*/
/*border-color: #ea6060;*/
/*transition: .6s;*/
/*}*/

.pink-btn {
    background: #fff;
    border: 1px solid #222222;
    margin-left: 10px;
    color: #222222;
    transition: .6s;
}

.pink-btn:hover {
    background-color: #ea6060;
    color: #fff;
    text-decoration: none;
    border: 1px solid #ea6060;
}

.btn-white {
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;
}

.btn-white:hover {
    border: 1px solid #000;
    color: #000;
}

.btn-md {
    border: 1px solid #fff;
    color: #fff;
    width: 162px;
    height: 52px;
    background: #ea6060;
}

.btn-md:hover {
    border: 1px solid #fff;
    color: #fff;
    background: #bfc2c5;
}

.btn-md2 {
    border: 1px solid #222222;
    color: #222222;
    width: 145px;
    height: 42px;
    background: #fff;
    border-radius: 4px;
    padding: .6em;
}

.btn-md2:hover {
    border: 1px solid #bfc2c5;
    color: #222222;
    background: #bfc2c5;
}

/*.btn-default:active, .btn-default:hover  {
    background: transparent;
}*/


/*
Extra height css
*/
.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.padding-top-20 {
    padding-top: 1.33rem;
}

.padding-bottom-20 {
    padding-bottom: 1.33rem;
}

.padding-top-40 {
    padding-top: 2.66rem;
}

.padding-bottom-40 {
    padding-bottom: 2.66rem;
}

.padding-top-60 {
    padding-top: 5rem;
}

.padding-bottom-60 {
    padding-bottom: 5rem;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

/*padding*/
.padding-twenty {
    padding: 10px 0;
}

.padding-fourty {
    padding: 20px 0;
}

.padding-sixty {
    padding: 30px 0;
}

.padding-eighty {
    padding: 40px 0;
}

h1 span {
    color: #f56363;
    font-weight: 400;
}

/*for placeholder changes*/

/*input::-webkit-input-placeholder {
  color: #CACACA;
  font-size: 18px;
}
input::-moz-placeholder {
  color: #CACACA;
  font-size: 18px;
}
input:-ms-input-placeholder {
  color: #CACACA;
  font-size: 18px;
}*/

/*for image bg*/


/*.img-bg{
  background: url(../images/home-msg.jpg) no-repeat center top fixed;
  background-size
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}*/
/* navbar section */
.menu-scroll {
    padding-top: 0px;
    padding-bottom: 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 300;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-color: #3d3325 !important;

}

.main_menu_bg {
    margin-top: 0px;
    transition: background-color .3s ease-in-out;
}

.main_menu_bg .navbar-default {
    background-color: transparent;
    border-color: transparent;
}

.navbar-default .navbar-nav > li > a {
    font-size: 15px;
    font-weight: bold;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #14a9d7;
}

.main_menu_bg .navbar {
    margin-bottom: 0px;
    border-bottom: 1px solid #686868;
    min-height: 90px;
}

.main_menu_bg .container-fluid {
    margin-top: 20px;
}

.main_menu_bg .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 33px;
    text-transform: uppercase;
}

.main_menu_bg .navbar-default .navbar-nav > .active > a {
    color: #14a9d7;
    background-color: transparent;
    border-bottom: 1px solid #FFF;
    margin-bottom: -1px;
    padding-bottom: 33px;

}


/* Home Section */
.home {
    background: url(../images/homebg.jpg) no-repeat 100% 100%;
    text-align: center;
}


.single_slider p {
    max-width: 70%;
    font-size: 2.375rem;
    margin: 10px auto;
    font-family: 'latoheavy_italic';
    line-height: 45px;
    color: #fff;
}

.single_slider h2 {
    color: #fff;
    font-size: 4.525rem;
    font-family: 'latobold';
    margin-bottom: 50px;
}

.main_slider_area .owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    margin-top: 160px;
}

.main_slider_area .owl-theme .owl-nav [class*='owl-'] {
    color: black;
    background: transparent;
}

.main_slider_area .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: white;
    text-decoration: none;
}

.main_slider_area .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: white;
    text-decoration: none;
}


/* Register Section */

.register {
    width: 100%;
    overflow: hidden;
}

.register .single_login {
    background: #21424a;
}

.single_register {
    background: #2fac6c;
    transform: skewX(-20deg);
}

.single_register a {
    color: #fff;
    font-size: 1.375rem;
    font-family: 'lotobold';
    text-transform: uppercase;
    padding: 25px 0px;
    display: inline-block;
    transform: skewX(20deg);
}

/* domainsearce section */
.domainsearce {
    padding: 30px 0px;
}

.main_somainsearrce {
}

.main_somainsearrce {
}

.head_title {
    margin-bottom: 40px;
}

.head_title h2 {
    font-size: 1.5rem;
    color: #383737;
    font-family: 'latoheavy';
}

.head_title p {
    font-size: 1rem;
    color: #a2a2a2;
    font-family: 'latolight';
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 1px;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 2px;
}

.sercelist {
    border: 1px solid #ddd;
    padding: 20px;
    display: inline-block;
    width: 100%;
    margin-top: 40px;
}

.sercelist .list-inline::after {
    content: '';
    display: block;
    clear: both;
}

.sercelist .list-inline {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.sercelist .list-inline > li {
    /*display: inline-block;*/
    width: 25%;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #ddd;
    height: 100px;
    /*line-height: 100px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sercelist .list-inline > li.line-break {
    width: 100%;
    height: 0;
    border: 0;
    border-top: 0;
}

.sercelist .list-inline > li:last-child {
    /*border-right: 0px solid #ddd;*/

}

.sercelist .list-inline.no-border > li {
    border-bottom: 0px solid #ddd;

}

.sercelist .single_dotcom_serce ul.list-inline li {
    position: relative;
}

.sercelist .single_dotcom_serce ul.list-inline.no-border li:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    left: -7%;
    top: -15%;
    border-radius: 50%;
}

.sercelist .single_dotcom_serce ul.list-inline.no-border li:nth-child(1):after {
    display: none;
}


/* pricing top section */

.pricingtop {
    background: url(../images/pricingbg.jpg) no-repeat 100% 100%;
    background-size: cover;
}

/* Pricing Section */
.pricing {
    margin-top: -250px;
}

.pricing_content {
    width: 100%;
    margin: 0 auto;
}

/*.pricing .pricing_head .icon_area{
	border-top-left-radius: 10px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 35px;
}*/
.pricing .pricing_head .icon_area.p_icon_one i {
    border-top: 5px solid #ffcc33;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    color: #ffcc33;

}

.pricing .pricing_head .icon_area.p_icon_two i {
    border-top: 5px solid #7f8c8d;
    border-right: 5px solid #7f8c8d;
    border-left: 5px solid #fff;
    border-bottom: 5px solid #fff;
    color: #7f8c8d;
}

.pricing .pricing_head .icon_area.p_icon_three i {
    border-bottom: 5px solid #f85f40;
    border-right: 5px solid #f85f40;
    border-top: 5px solid #f85f40;
    border-left: 5px solid #fff;
    color: #f85f40;
}

.pricing .pricing_head .icon_area.p_icon_four i {
    border: 5px solid #1abc9c;
    color: #1abc9c;
}

.pricing .pricing_head .icon_area i {
    color: #ff0000;
    font-size: 1.0625rem;
    padding: 20px;
    border-radius: 50%;
    display: inline-block;
    border-top: 5px solid #ff0000;
}

.single_pricing.pricing_color_one {
    border-top: 5px solid #ffcc33;
}

.single_pricing.pricing_color_two {
    border-top: 5px solid #7f8c8d;
}

.single_pricing.pricing_color_three {
    border-top: 5px solid #f85f40;
}

.single_pricing.pricing_color_four {
    border-top: 5px solid #1abc9c;
}

.single_pricing {
    border: 1px solid #ddd;
    padding: 40px 0px;
    margin-top: 20px;
    border-top: 3px solid #ffcc33;
    background: #fff;
}

.pricing_head h3 {
    text-transform: uppercase;
    color: #222222;
    font-size: 14px;
    font-family: 'titillium_webbold';
}

.single_pricing .single_pricing_figure {
    padding: 0px 15px;
}

.single_pricing .single_pricing_figure ul li span.princing_name {
    text-align: left;
    display: inline-block;
    width: 80%;
}

.single_pricing .single_pricing_figure ul li span.princing_price {
    text-align: right;
    display: inline-block;
    width: 15%;
}

.single_pricing .single_pricing_figure ul li span.princing_price_one {
    color: #f9d423;
}

.single_pricing .single_pricing_figure ul li span.princing_price_two {
    color: #7f8c8d;
}

.single_pricing .single_pricing_figure ul li span.princing_price_three {
    color: #f85f40;
}

.single_pricing .single_pricing_figure ul li span.princing_price_four {
    color: #1abc9c;
}

.single_pricing ul {
    margin-top: 25px;
    list-style: none;
    padding: 0;
}

.single_pricing ul li {
    line-height: 45px;
    height: 45px;
}

.single_pricing footer.pricing_footer {
    margin-top: 30px;
}

.single_pricing footer.pricing_footer h3 {
    font-size: 1.8975rem;
    margin-bottom: 0px;
    font-family: 'latobold';
}

.single_pricing footer.pricing_footer h3 span.dolor {
    font-size: 1.3rem;
    font-family: 'latoheavy';
}

.single_pricing footer.pricing_footer h3 span.month {
    font-size: 0.8125rem;
    font-family: 'latoregular';

}

.single_pricing footer.pricing_footer a {
    font-size: 0.9875rem;
    color: #282828;
    margin-top: 20px;
    font-family: 'latobold';
}

.single_pricing footer.pricing_footer .separetor_one {
    border: 3px solid #f9d423;
}

.single_pricing footer.pricing_footer .separetor_two {
    border: 3px solid #7f8c8d;
}

.single_pricing footer.pricing_footer .separetor_three {
    border: 3px solid #f85f40;
}

.single_pricing footer.pricing_footer .separetor_four {
    border: 3px solid #1abc9c;
}


/* Abouts section */
.abouts_area {
    display: inline-block;
    border-top: 1px dashed #ddd;
    margin-top: 90px;
    padding-top: 95px;
    margin-bottom: 80px;
}

.abouts_area .single_about {
    border-right: 1px dashed #ddd;
    padding-right: 100px;
}

.abouts_area .single_about h3 {
    font-size: 2.1875rem;
    font-family: 'latosemibold';

}

.abouts_area .single_about p {
    font-size: 1.125rem;
    font-family: 'latothin';

}


.abouts_area .single_team .team_img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #e5e5e5;
}

.abouts_area .single_team .team_deatels {
    margin-top: 10px;
}

.abouts_area .single_team .team_deatels h4 {
    font-size: 1.0625rem;
    color: #303030;
    margin-bottom: 0px;

}

.abouts_area .single_team .team_deatels p {
    color: #8e8e8e;
    font-size: 0.9375rem;

}

.abouts_area .single_team .team_deatels .team_socail {
    margin-left: -15px;
}

.abouts_area .single_team .team_deatels .team_socail a {
    margin-left: 15px;
    display: inline-block;
}

.abouts_area .single_team .team_deatels .team_socail a i {
    color: #bfbfbf;
    font-size: 0.875rem;
}

.abouts_area .single_team .team_deatels .team_socail a:hover i {
    color: #1f89ba;
}


/* testimonial section */

.comments {
    background: url(../images/commnsbg.jpg) no-repeat 100% 100%;
    background-size: cover;
}

.comments .single_comments p {
    max-width: 70%;
    margin: 20px auto;
    color: #fff;
    font-size: 1.25rem;
}

.comments .single_comments a {
    color: #fff;
    font-size: 1.25rem;
}

.comments .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: transparent;
    border: 2px solid #fff;
}

.owl-theme .owl-dots .owl-dot span {
    background: #fff;
}

/* service section */

.service {
    padding: 80px 0px;
}

.main_service {
}

.single_service {
    margin-top: 20px;
}

.single_service .single_service_icon {
    margin-bottom: 30px;
}

.single_service .single_service_icon a i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.75rem;
    color: #fff;
    border-radius: 50%;
    text-align: center;

}

.single_service .single_service_icon.icon_one a i {
    background: #f1c40f;
}

.single_service .single_service_icon.icon_two a i {
    background: #0faff1;
}

.single_service .single_service_icon.icon_three a i {
    background: #95a5a6;
}

.single_service .single_service_icon.icon_four a i {
    background: #f1540f;
}

.single_service .single_service_deatels h4 {
    font-size: 1.125rem;
    margin-bottom: 0px;

}

.single_service .single_service_deatels p {
    font-size: 1.01rem
}


/*footer section */
.footer {
    background: #2a2a2a;
    padding: 80px 0px;
}

.footer .main_footer {
    border-bottom: 1px solid #686868;
    display: inline-block;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.footer .footer_logo {
    text-align: left;
}

.footer .footer_menu {
    text-align: right;
}

.footer .footer_menu ul {
    margin-right: -12px;
}

.footer .footer_menu ul::after {
    content: '';
    display: block;
    clear: both;
}

.footer .footer_menu li {
    float: right;
    padding: 0;
}

.footer .footer_menu li a {
    font-family: 'latomedium';
    padding: 10px 13px;
    float: right;
    font-size: 13px;
}

.footer a, p {
    color: #686868;
}

.footer a:hover,
i:hover {
    color: #fff;
}

.single_right_footer ul li a i {
    color: #686868;
    padding-right: 5px;
}

.single_right_footer ul li:hover i {
    color: #fff;
}

.footer .fo_bottom_right {
    border-right: 1px solid #686868;
    margin-top: 20px;
}

.footer .fo_bottom_socail {
    margin-top: 20px;
}

.footer .fo_bottom_socail a i {
    padding-right: 5px;
    display: inline-block;
}

.footer .fo_bottom_socail p, a {
    font-size: 13px;
    display: block;
}

.coppyright {
    overflow: hidden;
    display: block;
    width: 100%;
    background: #3d3325;
    text-align: center;
}

.coppyright p {
    display: inline-block;
    color: #fff;
    margin: 20px 0px;
}

.coppyright p i {
    display: inline-block;
    color: #ff0000;
    padding: 0px 10px;
}

.coppyright p a {
    display: inline-block;
    color: #ff0000;
    font-weight: 700;
    padding: 0px 10px;
    font-size: 18px;
}


/* scrollup */

.scrollup {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    opacity: .3;
    position: fixed;
    bottom: 20px;
    right: 25px;
    color: #fff;
    cursor: pointer;
    background-color: #000;
    z-index: 1000;
    transition: opacity .5s, background-color .5s;
    -moz-transition: opacity .5s, background-color .5s;
    -webkit-transition: opacity .5s, background-color .5s;
}

.scrollup:hover {
    background: #222;
    opacity: 1;
}

.scrollup i {
    font-size: 13px;
    position: absolute;
    opacity: 1;
    color: #fff;
    left: 50%;
    top: 50%;
    margin-top: -7px;
    margin-left: -6px;
    text-decoration: none;

}

/* About us */
#speakers
footer {
    padding-top: 10rem;
    padding-bottom: 10rem;
    text-align: center;
}

#speakers {
    background: #f9f9f9;
}

#speakers h3 {
    font-size: 18px;
    margin-bottom: 0px;
}

#speakers h6 {
    color: #666;
    margin-top: 4px;
}

#speakers img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

#speakers .col-md-3 {
    display: block;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 22px;
}

#speakers .speakers-wrapper {
    background: #ffffff;
    padding-bottom: 22px;
}

.parallax-section {
    background-attachment: fixed !important;
    background-size: cover !important;
}

.section-title {
    margin-bottom: 32px;
}

.carousel .slide {
    background: none !important;
}

.comments .control-dots {
    position: relative !important;
}

.navbar-brand {
    padding: 0 15px;
}

.navbar-collapse.collapse {
    height: 0px;
    transition: height 1s ease-out;
    -webkit-transition: height 1s ease-out;
}

.navbar-collapse.collapse.in {
    max-height: none;
    height: auto;
    margin-top: 18px;
}

.navbar-collapse.collapse.in ul li a {
    padding: 10px 15px;
}


@media (max-width: 640px) {
    .home-overlay-fluid {
        padding: 100px 0;
    }

    .home-overlay-fluid p {
        font-size: 18px;
        word-break: break-all;
        margin: 0;
        max-width: none;
    }

    .home-overlay-fluid h2 {
        font-size: 23px;
        word-break: break-all;
        margin: 0;
    }

    .item-name {
        width: 60% !important;
    }

    .footer_logo {
        text-align: center !important;
    }

    .footer_menu ul {
        text-align: center !important;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
    }

    .navbar-brand img {
        width: 95px;
    }
}

@media (min-width: 641px) and (max-width: 767px) {
    .home-overlay-fluid {
        padding: 150px 0px;
    }

    .home-overlay-fluid p {
        font-size: 22px;
        word-break: break-all;
        margin: 0;
        max-width: none;
    }

    .home-overlay-fluid h2 {
        font-size: 50px;
        word-break: break-all;
        margin: 0;
    }

    .footer_logo {
        text-align: center !important;
    }

    .footer_menu ul {
        text-align: center !important;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
    }

    .navbar-brand img {
        width: 95px;
    }
}

@media (min-width: 768px) and (max-width: 1022px) {
    .home-overlay-fluid {
    }

    .home-overlay-fluid h2 {
        font-size: 55px;
        margin: 0;
    }
}
